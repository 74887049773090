// component/Calendar/CalendarWeek.js

import React, { Component } from 'react';

import CalendarDay from './CalendarDay';

class CalendarWeek extends Component {

  drawWeek = (pointer, stone, today, events) => [...Array(7)].map((x, i) => {
    const day = pointer.format('D');
    const isExtra = !pointer.isSame(stone, 'month');
    const isToday = pointer.isSame(today, 'day');
    pointer.add(1, 'days');

    return <CalendarDay key={ i } day={ day } isToday={ isToday } isExtra={ isExtra } events={ events[day] } isFetching={ this.props.isFetching } />;
  });

  render = () => {
    const { pointer, stone, today, events } = this.props;
    return (
      <tr>{ this.drawWeek(pointer, stone, today, events) }</tr>
    );
  }

}

export default CalendarWeek;