// components/Main.js

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TopMenu from './TopMenu';

class Main extends Component {
    
  render = () => {
    const { children } = this.props;
    return (
      <div className="container">
        <TopMenu />
        { children }
      </div>
    );
  }

}

Main.propTypes = {
  children: PropTypes.node
};

export default Main;