// components/Grid/GridCard.js

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import { formatData } from '../../utils/utils';

import '../../css/GridCard.css';

class GridCard extends Component {

  render = () => {

    const view = 'grid';
    const { type, cols, card } = this.props;
    return (
      <div className="card">
        <div className="card-body">
          { cols.map( (col, i) => {
            const value = formatData(col, card, type, view);
            return value && <p key={ i } className="card-text"><b>{ col.title }:</b><span>{ value }</span></p>
          }) }
        </div>
        <div className="card-footer text-center">
          <Link
            to={{ 
              pathname: "/" + type + "/edit/" + card._id,
              state: {
                action: 'edit'
              }
            }}
            className="btn btn-dark btn-sm mx-2"
          ><FontAwesomeIcon icon={['far', 'edit']} /> Edit</Link>
          <Link
            to={{ 
              pathname: "/" + type + "/delete/" + card._id,
              state: {
                action: 'delete'
              }
            }}
            className="btn btn-dark btn-sm mx-2"
          ><FontAwesomeIcon icon={['far', 'trash-alt']} /> Delete</Link>
        </div>
      </div>
    )
  }

}

GridCard.propTypes = {
  type: PropTypes.string,
  card: PropTypes.object,
  cols: PropTypes.array
};

export default GridCard;