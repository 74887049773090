// components/App.js

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import { auth } from '../actions/Auth';

import Login from './Auth/Login';
import Logout from './Auth/Logout';

import Main from './Main';
import Home from './Home';

import Calendar from './Calendar/Calendar';
import Grid from './Grid/Grid';
import Modal from './Modal';
import Table from './Table/Table';

import '../css/App.css';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      view: 'table' /** grid|table */
    }
  }

  componentDidMount = () => {
    document.title = 'DOMU';
    this.props.authCheck();
  }

  changeViewType = (type) => {
    this.setState({ view: type });
  }

  render = () => {
    const { isFetching, isAuthenticated } = this.props;
    const { view } = this.state;
    if (isFetching) return <div className="row align-items-center justify-content-center h-100"><FontAwesomeIcon icon="spinner" size="10x" className="loading" /></div>;
    return (
      <Router>
        <Switch>
          {
            isAuthenticated
              ?
            <Main>
              <Switch>
                <Route path='/' exact component={ Home } />
                <Route path='/logout' exact component={ Logout } />
                <Route path='/calendar' component={ Calendar } />
                {
                  view === 'grid'
                    ?
                  <Route path='/:itemtype/:itemparam?' render={ props => <Grid view={ view } changeViewType={ this.changeViewType } { ...props } /> } />
                    :
                  <Route path='/:itemtype/:itemparam?' render={ props => <Table view={ view } changeViewType={ this.changeViewType } { ...props } /> } />
                }
              </Switch>
              <Route path='/calendar/:itemtype/:itemid' exact component={ Modal } />
              <Route path='/:itemtype/add' exact component={ Modal } />
              <Route path='/:itemtype/attach/:itempath' exact component={ Modal } />
              <Route path='/:itemtype/edit/:itemid' exact component={ Modal } />
            </Main>
              :
            <Route path='/' component={ Login } />
          }
        </Switch>
      </Router>
    );
  }
  
}
  
const mapStateToProps = (state) => {
  const { auth } = state;
  const { isFetching, isAuthenticated } = auth;
  return {
    isFetching,
    isAuthenticated
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authCheck: () => dispatch(auth('check'))
  };
};

App.propTypes = {
  isFetching: PropTypes.bool,
  isAuthenticated: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(App);