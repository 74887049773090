import React, { Component } from 'react';

import { apiServer } from '../config/config';

import '../css/Modal.css';

class Modal extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    let params = {
      history: this.props.history,
      id: null,
      modalSize: '',
      path: null,
      title: ' ' + this.props.match.params.itemtype,
      type: this.props.match.params.itemtype
    };
    let path;

    switch (this.props.location.state.action) {
      case 'add':
        path = './Form/Form' + this.props.match.params.itemtype.replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
        params = {
          ...params,
          title: 'Aggiungi' + params.title
        };
        break;
      case 'attach':
        path = './Pdf';
        params = {
          ...params,
          modalSize: 'modal-lg',
          path: apiServer + 'files/' + this.props.match.params.itemtype + '/' + decodeURIComponent(this.props.match.params.itempath)
        };
        break;
      case 'edit':
        path = './Form/Form' + this.props.match.params.itemtype.replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
        params = {
          ...params,
          id: this.props.match.params.itemid,
          title: 'Modifica' + params.title
        };
        break;
      case 'delete':

        break;
      case 'show':
        path = './Card';
        params = {
          ...params,
          id: this.props.match.params.itemid,
          title: 'Mostra' + params.title
        };
        break;
      default:
        break;
    }

    this.setState( params );

    if (path) {
      import(`${path}`)
        .then(module => this.setState({ module: module.default }))
    }
  }

  back = e => {
    e.stopPropagation();
    this.props.history.goBack();
  };

  render = () => {
    const { module: Component, history, id, modalSize, path, title, type } = this.state;
    return (
      <div className="modal" tabIndex="-1" role="dialog">
        <div className="modal-bg" onClick={ this.back }></div>
        <div className={ "modal-dialog " + modalSize } role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{ title }</h5>
              <button type="button" className="close" aria-label="Close" onClick={ this.back }>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              { Component && <Component history={ history } id={ id } path={ path } type={ type } /> }
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default Modal;