// components/Auth/Logout.js

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { auth } from '../../actions/Auth';

class Logout extends Component {

  componentDidMount = () => {
    this.props.authLogout();
  }

  render = () => {
    return <Redirect to="/"/>;
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    authLogout: () => dispatch(auth('logout'))
  };
};

export default connect(null, mapDispatchToProps)(Logout);