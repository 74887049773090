// reducers/Calendar.js

import {
/** CALENDAR FETCH MONTH */
  CALENDAR_FETCH_MONTH_REQUEST,
  CALENDAR_FETCH_MONTH_SUCCESS,
  CALENDAR_FETCH_MONTH_FAIL
} from '../actions/Calendar';

const initialState = {
  error: {},
  isFetching: false,
  events: {}
}

export default function calendar(state = initialState, action) {

  switch (action.type) {
/** CALENDAR FETCH MONTH */
    case CALENDAR_FETCH_MONTH_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching
      }
    case CALENDAR_FETCH_MONTH_SUCCESS:
      return {
        ...state,
        isFetching: action.isFetching,
        events: action.events
      }
    case CALENDAR_FETCH_MONTH_FAIL:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error
      }
/** DEFAULT */
    default:
      return state;
  }

}