// components/Table/TableRow.js

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import { formatData } from '../../utils/utils';

const TableRow = (props) => {

  const view = 'table';
  const { i, type, cols, row } = props;
  return (
    <tr>
      <td className="d-none">{ i }</td>
      { cols.map( (col, i) => {
        return <td key={ i }>{ formatData(col, row, type, view) }</td>
      }) }
      <td className="text-right">
        <Link
          to={{ 
            pathname: "/" + type + "/edit/" + row._id,
            state: {
              action: 'edit'
            }
          }}
          className="btn btn-dark btn-sm mx-2"
        ><FontAwesomeIcon icon={['far', 'edit']} /> Edit</Link>
        <Link
            to={{ 
              pathname: "/" + type + "/delete/" + row._id,
              state: {
                action: 'delete'
              }
            }}
            className="btn btn-dark btn-sm mx-2"
          ><FontAwesomeIcon icon={['far', 'trash-alt']} /> Delete</Link>
      </td>
    </tr>
  )

}

TableRow.propTypes = {
  type: PropTypes.string,
  row: PropTypes.object,
  cols: PropTypes.array
};

export default TableRow;