// component/Grid/GridCardPhantom

import React from 'react';

const GridCardPhantom = () => {
  
  return <div className="card invisible"></div>;

}

export default GridCardPhantom;