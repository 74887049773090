// component/Calendar/CalendarDay.js

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

class CalendarDay extends Component {

  convertToIcon = text => {
    let icon;

    switch (text.replace(/^\[([^\]]+)\].+$/, '$1')) {
      case 'bombole':
        icon = <FontAwesomeIcon icon={['fab', 'hotjar']} />;
        break;
      case 'enel':
        icon = <FontAwesomeIcon icon="bolt" />;
        break;
      case 'fitness':
        icon = <FontAwesomeIcon icon="heartbeat" />;
        break;
      case 'mutuo':
        icon = <FontAwesomeIcon icon="home" />;
        break;
      case 'tiscali':
        icon = <FontAwesomeIcon icon="phone" />
        break;
      default:
        break;
    }

    return icon;
  }

  drawEvents = events => Object.keys(events).map((key, i) => <li className={ "list-group-item " + key.replace(/^\[([^\]]+)\].+$/, '$1')} title={ key.replace(/^\[([^\]]+)\]/, '$1 -') } key={ i } ><Link
    to={{ 
      pathname: "/calendar/" + key.replace(/^\[([^\]]+)\].+$/, '$1') + "/" + events[key]._id,
      state: {
        action: 'show'
      }
    }}
  >{ this.convertToIcon(key) }{ key.replace(/\[[^\]]+\]/, '') }</Link></li>);

  render = () => {
    const { day, isToday, isExtra, events, isFetching } = this.props;
    return (
      <td>
        {
          isExtra
            &&
          <span className="extraCal"></span>
        }
        <span className={ "day rounded-circle " + (isToday && "bg-primary text-white text-center")}>{ day }</span>
        { !isExtra && !isFetching && events && <ul className="list-group events">{ this.drawEvents(events) }</ul> }
      </td>
    );
  }

}

export default CalendarDay;