// actions/Calendar.js

import axios from 'axios';

import { apiServer } from '../config/config';

export const CALENDAR_FETCH_MONTH_REQUEST = 'CALENDAR_FETCH_MONTH_REQUEST';
export const CALENDAR_FETCH_MONTH_SUCCESS = 'CALENDAR_FETCH_MONTH_SUCCESS';
export const CALENDAR_FETCH_MONTH_FAIL = 'CALENDAR_FETCH_MONTH_FAIL';

const instance = axios.create({
  withCredentials: true
});

/** Calendar fetch month functions */
export function calendarFetchMonth(year, month) {
  return (dispatch) => {
    dispatch(calendarFetchMonthRequest());

    instance.get(apiServer + 'calendar/' + year + '/' + month)
    .then(response => {
      dispatch(calendarFetchMonthSuccess(response.data));
    })
    .catch(error => {
      dispatch(calendarFetchMonthFail(error));
    });
  }
}

function calendarFetchMonthRequest() {
  return {
    type: CALENDAR_FETCH_MONTH_REQUEST,
    isFetching: true
  };
}

function calendarFetchMonthSuccess(events) {
  return {
    type: CALENDAR_FETCH_MONTH_SUCCESS,
    isFetching: false,
    events
  };
}

function calendarFetchMonthFail(error) {
  return {
    type: CALENDAR_FETCH_MONTH_FAIL,
    isFetching: false,
    error
  };
}
