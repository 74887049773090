// reducers/index.js

import { combineReducers } from 'redux';
import auth from './Auth';
import calendar from './Calendar';
import data from './Data';

const rootReducer = combineReducers({
  auth,
  calendar,
  data
});

export default rootReducer;