// components/Auth/Login.js

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { auth } from '../../actions/Auth';

import '../../css/Login.css';

class Login extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      email: 'sanna.enrico@gmail.com',
      password: 'pippo'
      /* email: '',
      password: '' */
    };
  }

  handleChange = (event) => {
    let change = {};
    change[event.target.id] = event.target.value;
    this.setState(change);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.authLogin(this.state);
  }

  render = () => {
    return (
      <div className="container">
        <div className="row align-items-center justify-content-center h-100">
          <form className="col-4" onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="email" className="sr-only">E-mail</label>
              <input type="email" value={this.state.email} id="email" placeholder="E-mail address" onChange={this.handleChange} className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="password" className="sr-only">Password</label>
              <input type="password" value={this.state.password} id="password" placeholder="Password" onChange={this.handleChange} className="form-control" />
            </div>
            <div className="form-group row justify-content-center">
              <button type="submit" className="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
      </div>
    );
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    authLogin: (data) => dispatch(auth('login', data))
  };
};

export default connect(null, mapDispatchToProps)(Login);