// component/ListFilter.js

import React from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import '../css/ListFilter.css';

const ListFilter = (props) => {

  const { cols, filterBy, setFilterBy, filterText, setFilterText, resetFilters } = props;
  return (
    <div className="col-4 text-right">
      <div className="input-group">
        <div className="input-group-prepend">
          <select className="custom-select" onChange={ setFilterBy } value={ filterBy }>
            <option value="">All fields</option>
            { cols.map(function (col, i) {
              return <option key={ i } value={ col.field }>{ col.title }</option>
            }) }
          </select>
        </div>
        <input type="text" className="form-control" onChange={ setFilterText } value={ filterText } placeholder="filter content" aria-label="filter content" />
        {
          filterText
            &&
          <div className="input-group-append">
            <button className="btn btn-outline-secondary" type="button" onClick={ resetFilters }><FontAwesomeIcon icon="times" /></button>
          </div>
        }
      </div>
    </div>
  );

}

ListFilter.propTypes = {
    cols: PropTypes.array,
    filterBy: PropTypes.string,
    filterText: PropTypes.string,
    resetFilters: PropTypes.func,
    setFilterBy: PropTypes.func,
    setFilterText: PropTypes.func
  };

export default ListFilter;