// reducers/Data.js

import {
/** DATA FETCH ALL */
  DATA_FETCH_ALL_REQUEST,
  DATA_FETCH_ALL_SUCCESS,
  DATA_FETCH_ALL_FAIL,
/** DATA FETCH ERROR */
  DATA_FETCH_ERROR_404,
/** DATA FETCH ONE */
  DATA_FETCH_ONE_REQUEST,
  DATA_FETCH_ONE_SUCCESS,
  DATA_FETCH_ONE_FAIL,
/** DATA SET ADD */
  DATA_SET_ADD_REQUEST,
  DATA_SET_ADD_SUCCESS,
  DATA_SET_ADD_FAIL,
/** DATA SET UPDATE */
  DATA_SET_UPDATE_REQUEST,
  DATA_SET_UPDATE_SUCCESS,
  DATA_SET_UPDATE_FAIL
} from '../actions/Data';

const initialState = {
  cols: [],
  error: {},
  is404: false,
  isFetching: false,
  list: [],
  redirectTo: null,
  single: {}
}

export default function data(state = initialState, action) {

  switch (action.type) {
/** DATA FETCH ALL */
    case DATA_FETCH_ALL_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching
      }
    case DATA_FETCH_ALL_SUCCESS:
      return {
        ...state,
        isFetching: action.isFetching,
        is404: action.is404,
        cols: action.cols,
        list: action.list
      }
    case DATA_FETCH_ALL_FAIL:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error
      }
/** DATA FETCH ERROR */
    case DATA_FETCH_ERROR_404:
      return {
        ...state,
        is404: action.is404
      }
/** DATA FETCH ONE */
    case DATA_FETCH_ONE_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching
      }
    case DATA_FETCH_ONE_SUCCESS:
      return {
        ...state,
        isFetching: action.isFetching,
        cols: action.cols,
        single: action.single
      }
    case DATA_FETCH_ONE_FAIL:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error
      }
/** DATA SET ADD */
    case DATA_SET_ADD_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching
      }
    case DATA_SET_ADD_SUCCESS:
      return {
        ...state,
        isFetching: action.isFetching,
        list: [ action.objAdded, ...state.list ],
        redirectTo: action.redirectTo
      }
    case DATA_SET_ADD_FAIL:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error
      }
/** DATA SET UPDATE */
    case DATA_SET_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching
      }
    case DATA_SET_UPDATE_SUCCESS:
      state.list[state.list.findIndex(obj => obj._id === action.objUpdated._id)] = action.objUpdated;
      return {
        ...state,
        isFetching: action.isFetching,
        list: [ ...state.list ],
        redirectTo: action.redirectTo
      }
    case DATA_SET_UPDATE_FAIL:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error
      }
/** DEFAULT */
    default:
      return state;
  }

}