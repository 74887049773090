// components/Error404.js

import React from 'react';

const Error404 = () => {
  return (
    <div className="row align-items-end justify-content-center h-100">
      <div>
        <p className="d-flex justify-content-center">Ooops! Where are you?</p>
        <img src="https://giant.gfycat.com/AccurateUnfinishedBergerpicard.gif" alt="404 Not Found" />
      </div>
    </div>
  );
}

export default Error404;