// config/config.js

export const cols = {
  bombole: [
    { title: 'Data acquisto', field: 'data_acquisto', type: 'datetime' },
    { title: 'Data inizio',   field: 'data_inizio',   type: 'date'     },
    { title: 'Data fine',     field: 'data_fine',     type: 'date'     },
    { title: 'Prezzo',        field: 'prezzo',        type: 'currency' },
    { title: 'Peso',          field: 'peso',          type: 'weight'   }
  ],
  enel: [
    { title: 'Numero fattura',  field: 'numero_fattura',      type: 'string'   },
    { title: 'Data inizio',     field: 'data_inizio_periodo', type: 'date'     },
    { title: 'Data fine',       field: 'data_fine_periodo',   type: 'date'     },
    { title: 'Data emissione',  field: 'data_emissione',      type: 'date'     },
    { title: 'Data scadenza',   field: 'data_scadenza',       type: 'date'     },
    { title: 'Data pagamento',  field: 'data_pagamento',      type: 'date'     },
    { title: 'Importo',         field: 'importo_totale',      type: 'currency' },
    { title: 'Fattura',         field: 'allegati.fattura',    type: 'pdf'      }
  ],
  facoltativa: [
    { title: 'Richiedente',     field: 'richiedente',                 type: 'string'   },
    { title: 'Data richiesta',  field: 'data_richiesta',              type: 'datetime' },
    { title: 'Base oraria',     field: 'base_oraria',                 type: 'boolean'  },
    { title: 'Totale giorni',   field: 'totale_giorni',               type: 'number'   },
    { title: 'Ricevuta',        field: 'allegati.ricevuta_domanda',   type: 'pdf'      },
    { title: 'Riepilogo',       field: 'allegati.riepilogo_domanda',  type: 'pdf'      }
  ],
  fitness: [
    { title: 'Data inizio',   field: 'data_inizio',       type: 'datetime' },
    { title: 'Data fine',     field: 'data_fine',         type: 'datetime' },
    { title: 'Corsa',         field: 'minuti_corsa',      type: 'minutes'  },
    { title: 'Distanza',      field: 'distanza_percorsa', type: 'distance' },
    { title: 'Addominali',    field: 'totale_addominali', type: 'number'   },
    { title: 'Flessioni',     field: 'totale_flessioni',  type: 'number'   },
    { title: 'Peso',          field: 'peso',              type: 'weight'   },
    { title: 'Circonferenza', field: 'circonferenza',     type: 'length'   }
  ],
  mutuo: [
    { title: 'Numero rata',     field: 'numero_rata',     type: 'string'   },
    { title: 'Data scadenza',   field: 'data_scadenza',   type: 'date'     },
    { title: 'Data pagamento',  field: 'data_pagamento',  type: 'date'     },
    { title: 'Importo',         field: 'importo_totale',  type: 'currency' },
    { title: 'Causale',         field: 'causale',         type: 'string'   }
  ],
  tiscali: [
    { title: 'Numero fattura',  field: 'numero_fattura',      type: 'string'   },
    { title: 'Data inizio',     field: 'data_inizio_periodo', type: 'date'     },
    { title: 'Data fine',       field: 'data_fine_periodo',   type: 'date'     },
    { title: 'Data emissione',  field: 'data_emissione',      type: 'date'     },
    { title: 'Data scadenza',   field: 'data_scadenza',       type: 'date'     },
    { title: 'Data pagamento',  field: 'data_pagamento',      type: 'date'     },
    { title: 'Importo',         field: 'importo_totale',      type: 'currency' },
    { title: 'Fattura',         field: 'allegati.fattura',    type: 'pdf'      }
  ]
}

export const apiServer = 'http://domu.tasinanta.net:4200/';