// actions/Auth.js

import axios from 'axios';

import { apiServer } from '../config/config';

export const AUTH_CHECK_REQUEST = 'AUTH_CHECK_REQUEST';
export const AUTH_CHECK_SUCCESS = 'AUTH_CHECK_SUCCESS';
export const AUTH_CHECK_FAIL = 'AUTH_CHECK_FAIL';
export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL';
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAIL = 'AUTH_LOGOUT_FAIL';

const instance = axios.create({
  withCredentials: true
});


/** Main Auth function */
export function auth(action, data) {
  return (dispatch) => {
    switch (action) {
      case 'check':
        dispatch(authCheck());
        break;
      case 'login':
        dispatch(authLogin(data));
        break;
      case 'logout':
        dispatch(authLogout());
        break;
      default:
        break;
    }
  }
}


/** Auth Check session functions */
function authCheck() {
  return (dispatch) => {
    dispatch(authCheckRequest());

    instance.post(apiServer + 'auth/check')
      .then(response => {
        dispatch(authCheckSuccess(response.status));
      })
      .catch(error => {
        dispatch(authCheckFail(error));
      });
  }
}

function authCheckRequest() {
  return {
    type: AUTH_CHECK_REQUEST,
    isFetching: true,
    isAuthenticated: false
  }
}

function authCheckSuccess(status) {
  return {
    type: AUTH_CHECK_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    status
  }
}

function authCheckFail(error) {
  return {
    type: AUTH_CHECK_FAIL,
    isFetching: false,
    isAuthenticated: false,
    error
  }
}


/** Auth Login user functions */
function authLogin(data) {
  return (dispatch) => {
    dispatch(authLoginRequest());

    instance.post(apiServer + 'auth/login', data)
      .then(response => {
        dispatch(authLoginSuccess(response.user));
      })
      .catch(error => {
        dispatch(authLoginFail(error));
      });
  }
}

function authLoginRequest() {
  return {
    type: AUTH_LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false
  }
}

function authLoginSuccess(user) {
  return {
    type: AUTH_LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    user
  }
}

function authLoginFail(error) {
  return {
    type: AUTH_LOGIN_FAIL,
    isFetching: false,
    isAuthenticated: false,
    error
  }
}


/** Auth Logout user functions */
function authLogout(data) {
  return (dispatch) => {
    dispatch(authLogoutRequest());

    instance.get(apiServer + 'auth/logout')
      .then(response => {
        dispatch(authLogoutSuccess(response.status));
      })
      .catch(error => {
        dispatch(authLogoutFail(error));
      });
  }
}

function authLogoutRequest() {
  return {
    type: AUTH_LOGOUT_REQUEST,
    isFetching: true
  }
}

function authLogoutSuccess(user) {
  return {
    type: AUTH_LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false
  }
}

function authLogoutFail(error) {
  return {
    type: AUTH_LOGOUT_FAIL,
    isFetching: false,
    error
  }
}