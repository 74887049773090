// reducers/Auth.js

import {
/** AUTH CHECK */
  AUTH_CHECK_REQUEST,
  AUTH_CHECK_SUCCESS,
  AUTH_CHECK_FAIL,
/** AUTH LOGIN */
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAIL,
/** AUTH LOGOUT */
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAIL
} from '../actions/Auth';

const initialState = {
  error: {},
  isAuthenticated: false,
  isFetching: false,
  user: {}
}

export default function auth(state = initialState, action) {

  switch (action.type) {
/** AUTH CHECK */
    case AUTH_CHECK_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated
      }
    case AUTH_CHECK_SUCCESS:
      return {
        ...state,
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated
      }
    case AUTH_CHECK_FAIL:
      return {
        ...state,
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated,
        error: action.error
      }
/** AUTH LOGIN */
    case AUTH_LOGIN_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated
      }
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated,
        user: action.user
      }
    case AUTH_LOGIN_FAIL:
      return {
        ...state,
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated,
        error: action.error
      }
/** AUTH LOGOUT */
    case AUTH_LOGOUT_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching
      }
    case AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        isFetching: action.isFetching,
        isAuthenticated: action.isAuthenticated
      }
    case AUTH_LOGOUT_FAIL:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error
      }
/** DEFAULT */
    default:
      return state;
  }

}