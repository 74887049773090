// actions/Data.js

import axios from 'axios';

import { apiServer, cols } from '../config/config';

export const DATA_FETCH_ALL_REQUEST = 'DATA_FETCH_ALL_REQUEST';
export const DATA_FETCH_ALL_SUCCESS = 'DATA_FETCH_ALL_SUCCESS';
export const DATA_FETCH_ALL_FAIL = 'DATA_FETCH_ALL_FAIL';
export const DATA_FETCH_ERROR_404 = 'DATA_FETCH_ERROR_404';
export const DATA_FETCH_ONE_REQUEST = 'DATA_FETCH_ONE_REQUEST';
export const DATA_FETCH_ONE_SUCCESS = 'DATA_FETCH_ONE_SUCCESS';
export const DATA_FETCH_ONE_FAIL = 'DATA_FETCH_ONE_FAIL';
export const DATA_SET_ADD_REQUEST = 'DATA_SET_ADD_REQUEST';
export const DATA_SET_ADD_SUCCESS = 'DATA_SET_ADD_SUCCESS';
export const DATA_SET_ADD_FAIL = 'DATA_SET_ADD_FAIL';
export const DATA_SET_UPDATE_REQUEST = 'DATA_SET_UPDATE_REQUEST';
export const DATA_SET_UPDATE_SUCCESS = 'DATA_SET_UPDATE_SUCCESS';
export const DATA_SET_UPDATE_FAIL = 'DATA_SET_UPDATE_FAIL';

const instance = axios.create({
  withCredentials: true
});


/** Main DataFetch function */
export function dataFetch(type, id) {
  return (dispatch) => {
    if (id) {
      dispatch(dataFetchOne(type, id));
    } else {
      dispatch(dataFetchAll(type));
    }
  }
}


/** Data fetch all functions */
function dataFetchAll(type) {
  return (dispatch) => {
    dispatch(dataFetchAllRequest());

    instance.get(apiServer + type)
    .then(response => {
      dispatch(dataFetchAllSuccess(cols[type], response.data));
    })
    .catch(error => {
      dispatch(dataFetchAllFail(error));
      if (error.response.status === 404) {
        dispatch(dataFetchError404());
      }
    });
  }
}

function dataFetchAllRequest() {
  return {
    type: DATA_FETCH_ALL_REQUEST,
    isFetching: true
  };
}

function dataFetchAllSuccess(cols, list) {
  return {
    type: DATA_FETCH_ALL_SUCCESS,
    isFetching: false,
    cols,
    list
  };
}

function dataFetchAllFail(error) {
  return {
    type: DATA_FETCH_ALL_FAIL,
    isFetching: false,
    error
  };
}


/** Data fetch one functions */
function dataFetchOne(type, id) {
  return (dispatch) => {
    dispatch(dataFetchOneRequest());

    instance.get(apiServer + type + '/' + id)
    .then(response => {
      dispatch(dataFetchOneSuccess(cols[type], response.data));
    })
    .catch(error => {
      dispatch(dataFetchOneFail(error));
      if (error.response.status === 404) {
        dispatch(dataFetchError404());
      }
    });
  }
}

function dataFetchOneRequest() {
  return {
    type: DATA_FETCH_ONE_REQUEST,
    isFetching: true
  };
}

function dataFetchOneSuccess(cols, single) {
  return {
    type: DATA_FETCH_ONE_SUCCESS,
    isFetching: false,
    is404: false,
    cols,
    single
  };
}

function dataFetchOneFail(error) {
  return {
    type: DATA_FETCH_ONE_FAIL,
    isFetching: false,
    error
  };
}


/** Data fetch error functions */
function dataFetchError404() {
  return {
    type: DATA_FETCH_ERROR_404,
    is404: true
  }
}


/** Main DataSet function */
export function dataSet(type, data) {
  return (dispatch) => {
    if (data._id) {
      dispatch(dataSetUpdate(type, data));
    } else {
      dispatch(dataSetAdd(type, data));
    }
  }
}


/** Data set add functions */
function dataSetAdd(type, data) {
  return (dispatch) => {
    dispatch(dataSetAddRequest());

    instance.post(apiServer + type + '/add', data)
    .then(response => {
      dispatch(dataSetAddSuccess(response.data, '/' + type));
    })
    .catch(error => {
      dispatch(dataSetAddFail(error));
    });
  }
}

function dataSetAddRequest() {
  return {
    type: DATA_SET_ADD_REQUEST,
    isFetching: true
  };
}

function dataSetAddSuccess(objAdded, redirectTo) {
  return {
    type: DATA_SET_ADD_SUCCESS,
    isFetching: false,
    objAdded,
    redirectTo
  }
}

function dataSetAddFail(error) {
  return {
    type: DATA_SET_ADD_FAIL,
    isFetching: false,
    error
  };
}

/** Data set update functions */
function dataSetUpdate(type, data) {
  return (dispatch) => {
    dispatch(dataSetUpdateRequest());

    instance.post(apiServer + type + '/update/' + data._id, data)
    .then(response => {
      dispatch(dataSetUpdateSuccess(response.data, '/' + type));
    })
    .catch(error => {
      dispatch(dataSetUpdateFail(error));
    });
  }
}

function dataSetUpdateRequest() {
  return {
    type: DATA_SET_UPDATE_REQUEST,
    isFetching: true
  };
}

function dataSetUpdateSuccess(objUpdated, redirectTo) {
  return {
    type: DATA_SET_UPDATE_SUCCESS,
    isFetching: false,
    objUpdated,
    redirectTo
  }
}

function dataSetUpdateFail(error) {
  return {
    type: DATA_SET_UPDATE_FAIL,
    isFetching: false,
    error
  };
}