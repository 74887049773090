// components/Table/TableHead.js

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

class TableHead extends Component {

  resetSort = () => {
    this.props.resetSort();
  }

  sort = (sortBy) => {
    this.props.sort(sortBy);
  }

  render = () => {
    const { type, cols, sortBy, sortDir } = this.props;
    return (
      <thead className="thead-inverse">
        <tr>
          { cols.map( (col, i) => {
            return (
              <th key={ i } onClick={ () => { this.sort(col.field) } }><span data-tip={ "Order by " + col.title }>{ col.title }</span> {
                sortBy && (sortBy === col.field) && (
                  (sortDir && (sortDir > 0) && <FontAwesomeIcon icon='sort-amount-up' />) || <FontAwesomeIcon icon='sort-amount-down' />
                )
              }</th>
            );
          } ) }
          <th className="text-right">
            <button className={ "btn btn-light mr-2 " + (sortBy || sortDir || "d-none") } data-tip="Remove sort" onClick={ this.resetSort }><span className="fa-layers fa-fw">
              <FontAwesomeIcon icon='sort-amount-down' />
              <FontAwesomeIcon icon='times' color='red' data-fa-transform='shrink-4 top-10 right-6' />
            </span></button>
            <Link
              to={{ 
                pathname: "/" + type + "/add/",
                state: {
                  action: 'add'
                }
              }}
              className="btn btn-light"
              data-tip="Add new item"
            ><FontAwesomeIcon icon={['far', 'plus-square']} /> Add</Link>
            <ReactTooltip effect="solid" />
          </th>
        </tr>
      </thead>
    )
  }

}

TableHead.propTypes = {
  type: PropTypes.string,
  cols: PropTypes.array,
  sort: PropTypes.func,
  resetSort: PropTypes.func,
  sortBy: PropTypes.string,
  sortDir: PropTypes.number
};

export default TableHead;