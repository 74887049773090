// utils/utils.js

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

export const formatData = (col, obj, type, view) => {
  let data;

  switch (col.type) {
    case 'boolean':
      data = obj[col.field] ? <FontAwesomeIcon icon="check" /> : <FontAwesomeIcon icon="times" />;
      break;
    case 'currency':
      if (obj[col.field]) {
        data = new Intl.NumberFormat('it-IT', {
          style: 'currency',
          currency: 'EUR'
        }).format(obj[col.field]);
      }
      break;
    case 'date':
      if (obj[col.field]) {
        data = moment(obj[col.field]).utc().format('DD/MM/YYYY')
      }
      break;
    case 'datetime':
      if (obj[col.field]) {
        data = moment(obj[col.field]).utc().format('DD/MM/YYYY, HH:mm')
      }
      break;
    case 'distance':
      if (obj[col.field]) {
        data = obj[col.field] + ' km';
      }
      break;
    case 'length':
      if (obj[col.field]) {
        data = obj[col.field] + ' cm';
      }
      break;
    case 'minutes':
      if (obj[col.field]) {
        data = obj[col.field] + '\'';
      }
      break;
    case 'pdf':
      const subfields = col.field.split('.');
      if (obj[subfields[0]] && obj[subfields[0]][subfields[1]]) {
        const path = obj[subfields[0]][subfields[1]];

        if (view === 'grid') {
          data = <Link
            to={{ 
              pathname: '/' + type + '/attach/' + encodeURIComponent(path),
              state: {
                action: 'attach'
              }
            }}
            className="btn btn-link d-block p-0 text-danger text-truncate"
            title={ path.substr(path.lastIndexOf('/') + 1) }
          >{ path.substr(path.lastIndexOf('/') + 1) }</Link>;
        } else if (view === 'table') {
          data = <Link
            to={{ 
              pathname: '/' + type + '/attach/' + encodeURIComponent(path),
              state: {
                action: 'attach'
              }
            }}
            className="btn btn-light border border-danger text-danger btn-sm mx-2"
            title={ path.substr(path.lastIndexOf('/') + 1) }
          ><FontAwesomeIcon icon={['far', 'file-pdf']} /> PDF</Link>;
        }
      }
      break;
    case 'weight':
      if (obj[col.field]) {
        data = obj[col.field] + ' kg';
      }
      break;
    default:
      if (obj[col.field]) {
        data = obj[col.field];
      }
  }

  return data;
}


const convertDate = {
  toISODate(date) {
    const funcs = [
      'setDate',
      'setMonth',
      'setYear',
      'setHours',
      'setMinutes',
      'setSeconds',
      'setMilliseconds'
    ];
    let d = new Date();
    
    funcs.forEach(function(func, i) {
      convertDate[func](d, date[i]);
    });
    
    return d.toISOString();
  },
  setDate(date, day) { date.setUTCDate(day); },
  setMonth(date, month) { date.setUTCMonth(month -1); },
  setYear(date, year) { date.setUTCFullYear(year); },
  setHours(date, hours) { date.setUTCHours(hours); },
  setMinutes(date, minutes) { date.setUTCMinutes(minutes); },
  setSeconds(date, seconds) { date.setUTCSeconds(seconds); },
  setMilliseconds(date, milliseconds) { date.setUTCMilliseconds(milliseconds); }
}


export function parseData(field) {
  const type = field.dataset.type;
  const name = field.name;
  let value = field.value;
  let time = [];

  if (value) {
    switch (type) {
      case 'date':
        (['data_inizio', 'data_pagamento'].indexOf(name) >= 0 ) && (time = [0, 0, 0, 0]);
        (['data_fine', 'data_scadenza'].indexOf(name) >= 0 ) && (time = [23, 59, 59, 999]);
        value = convertDate.toISODate(value.split('/').concat(time));
        break;
      case 'datetime':
      value = convertDate.toISODate(value.replace(/[ :]/g, '/').split('/').concat([0, 0]));
        break;
      case 'float':
        value = parseFloat(value);
        break;
      case 'int':
        value = parseInt(value, 10);
        break;
      default:
        break;
    }
  }

  return value;
}


export function validateData(field) {
  const type = field.dataset.type;
  const required = field.required;
  let check = false;
  let error = '';
  let value = field.value;

  if (!value) {
    if (required) {
      error = 'Please, fill this required field';
    } else {
      check = true;
    }
  } else {
    switch (type) {
      case 'date':
        (check = /^(\d{2}\/){2}\d{4}$/.test(value)) || (error = 'Not a valid date (dd/mm/yyyy)');
        break;
      case 'datetime':
        (check = /^(\d{2}\/){2}\d{4} \d{2}:\d{2}$/.test(value)) || (error = 'Not a valid datetime (dd/mm/yyyy hh:mm)');
        break;
      case 'float':
        (check = (value = parseFloat(value))) || (error = 'Not a valid float');
        break;
      case 'int':
        (check = (value = parseInt(value, 10))) || (error = 'Not a valid integer');
        break;
      default:
        break;
    }
  }

  return { check, error, value }
}