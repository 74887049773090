// components/TopMenu.js

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

class TopMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      DropDownCaret: 'down',
      DropDownDisplay: 'none'
    }
  }

  expandDropDown = e => {
    e.preventDefault();
    const DropDownCaret = this.state.DropDownCaret === 'down' ? 'up' : 'down';
    const DropDownDisplay = this.state.DropDownDisplay === 'none' ? 'block' : 'none';
    this.setState({ DropDownCaret, DropDownDisplay });
  }

  render = () => {
    const { DropDownCaret, DropDownDisplay } = this.state;
    return (
      <nav className="navbar navbar-expand-lg fixed-top navbar-dark bg-dark">
        <NavLink to="/" exact className="navbar-brand" activeClassName="selected">DOMU</NavLink>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#menuTop" aria-controls="menuTop" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="menuTop">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <NavLink to="/bombole" className="nav-link" activeClassName="active"><FontAwesomeIcon icon={['fab', 'hotjar']} /> Bombole</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/enel" className="nav-link" activeClassName="active"><FontAwesomeIcon icon="bolt" /> Enel</NavLink>
            </li>
            <li className="nav-item dropdown">
              <NavLink to="/facoltativa" className="nav-link" activeClassName="active" onClick={ this.expandDropDown }><FontAwesomeIcon icon="child" /> Facoltativa <FontAwesomeIcon icon={ 'caret-' + DropDownCaret } /></NavLink>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown" style={ { display:DropDownDisplay } }>
                <a className="dropdown-item" href="/facoltativa/SNNNRC78H04B354L">Enrico</a>
                <a className="dropdown-item" href="/facoltativa/BRGMNL78T48B354D">Manuela</a>
              </div>
            </li>
            <li className="nav-item">
              <NavLink to="/fitness" className="nav-link" activeClassName="active"><FontAwesomeIcon icon="heartbeat" /> Fitness</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/mutuo" className="nav-link" activeClassName="active"><FontAwesomeIcon icon="home" /> Mutuo</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/tiscali" className="nav-link" activeClassName="active"><FontAwesomeIcon icon="phone" /> Tiscali</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/calendar" className="nav-link" activeClassName="active"><FontAwesomeIcon icon={['far', 'calendar-alt']} /> Calendar</NavLink>
            </li>
          </ul>
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink to="/logout" className="nav-link">logout <FontAwesomeIcon icon="sign-out-alt" /></NavLink>
            </li>
          </ul>
        </div>
      </nav>
    );
  }

}

export default TopMenu;