// component/Calendar/Calendar.js

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import { calendarFetchMonth } from '../../actions/Calendar';

import CalendarWeek from './CalendarWeek';

import '../../css/Calendar.css';

class Calendar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pointer: moment().set('date', 1),
      showMonths: false,
      showYears: false,
      today: moment()
    }
  }

  componentDidMount = () => {
    this.props.calendarFetchMonth(this.state.pointer.year(), this.state.pointer.month());
    this.updateToday();
  }

  componentWillUpdate = (nextProps, nextState) => {
    if (nextState.pointer !== this.state.pointer) {
      this.props.calendarFetchMonth(nextState.pointer.year(), nextState.pointer.month());
    }
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeout);
  }

  updateToday = () => {
    this.timeout = setTimeout(() => {
      this.setState({ today: moment() }, () => {
        this.updateToday();
      });
    }, 1000);
  }

  daysOfWeek = () => [...Array(7)].map((x, i) => {
    return <th key={ i }>{ moment.weekdays(i) }</th>;
  });

  drawMonths = () => [...Array(3)].map((x, i) => {
    return <tr key={ i }>{ [...Array(4)].map((y, j) => {
      return (
        <td key={ j } className="align-middle text-center">
          <button className="btn btn-lg btn-link" onClick={ this.setMonth } data-month={ (i*4) + j }>{ moment.months((i*4) + j) }</button>
        </td>
      );
    }) }</tr>;
  });

  drawWeeks = events => {
    const pointer = moment(this.state.pointer);
    const stone = moment(this.state.pointer);
    const today = moment(this.state.today);
    const weeksInMonth = Math.ceil((parseInt(pointer.daysInMonth(), 10)+ parseInt(pointer.startOf('month').format('d'), 10)) / 7);

    pointer.subtract(pointer.startOf('month').format('d'), 'days');

    return [...Array(weeksInMonth)].map((x, i) => {
      return <CalendarWeek key={ i } pointer={ pointer } stone={ stone } today={ today } events={ events } isFetching={ this.props.isFetching } />;
    });
  }

  drawYears = () => {
    const startingYear = 2009;
    const totYears = parseInt(moment(this.state.today).format('YYYY'), 10) - startingYear + 1;
    const rows = Math.floor(Math.sqrt(totYears));
    const cols = Math.ceil(totYears / rows);

    return [...Array(rows)].map((x, i) => {
      return <tr key={ i }>{ [...Array(cols)].map((y, j) => {
        if (moment(startingYear + (i*4) + j + '0630').isAfter(this.state.today, 'year')) return false;
        return (
          <td key={ j } className="align-middle text-center">
            <button className="btn btn-lg btn-link" onClick={ this.setYear } data-year={ startingYear + (i*4) + j }>{ startingYear + (i*4) + j }</button>
          </td>
        );
      }) }</tr>;
    });
  }

  nextMonth = () => this.setState({ pointer: moment(this.state.pointer).add(1, 'months') });
  prevMonth = () => this.setState({ pointer: moment(this.state.pointer).subtract(1, 'months') });
  setMonth = (e) => this.setState({ pointer: moment(this.state.pointer).month(e.target.dataset.month), showMonths: false });
  setYear = (e) => this.setState({ pointer: moment(this.state.pointer).year(e.target.dataset.year), showYears: false });
  showMonths = () => this.setState({ showMonths: !this.state.showMonths, showYears: false });
  showYears = () => this.setState({ showMonths: false, showYears: !this.state.showYears });
  today = () => this.setState({ pointer: moment(this.state.today) });

  render = () => {
    const { events } = this.props;
    const { pointer, showMonths, showYears } = this.state;
    return (
      <div>
        <table className="table table-bordered" id="calendar">
          <thead>
            <tr><th colSpan="7">
              <div className="d-flex justify-content-between">
                <button className="btn btn-light" data-tip="Previous month" onClick={ this.prevMonth }>
                  <FontAwesomeIcon icon='angle-double-left' />
                </button>
                <h3><span onClick={ this.showMonths }>{ pointer.format('MMMM') }</span> <span onClick={ this.showYears }>{ pointer.format('YYYY') }</span></h3>
                <button className="btn btn-light" data-tip="Next month" onClick={ this.nextMonth }>
                  <FontAwesomeIcon icon='angle-double-right' />
                </button>
                {/* <button className="btn btn-light" data-tip="Today" onClick={ this.today }>Today</button> */}
              </div>
            </th></tr>
            {
              showMonths
                ||
              <tr>{ this.daysOfWeek() }</tr>
            }
          </thead>
          <tbody>
            {
              (showMonths && this.drawMonths())
                ||
              (showYears && this.drawYears())
                ||
              this.drawWeeks(events)
            }
          </tbody>
        </table>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  const { calendar } = state;
  const { events, isFetching } = calendar;
  return {
    events,
    isFetching
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    calendarFetchMonth: (year, month) => dispatch(calendarFetchMonth(year, month))
  };
};

Calendar.propTypes = {
  events: PropTypes.object,
  isFetching: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);