// component/ListSwitchView.js

import React from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

const ListSwitchView = (props) => {

  const { view, changeViewType } = props;
  return (
    <div className="col-4 text-right">
      <div className="btn-group" role="group" aria-label="Switch view">
        <button onClick={ () => { changeViewType('grid') } } className="btn btn-info btn-sm" disabled={ view === 'grid' }><FontAwesomeIcon icon="th" /></button>
        <button onClick={ () => { changeViewType('table') } } className="btn btn-info btn-sm" disabled={ view === 'table' }><FontAwesomeIcon icon="table" /></button>
      </div>
    </div>
  );

}

ListSwitchView.propTypes = {
  changeViewType: PropTypes.func,
  view: PropTypes.string
};

export default ListSwitchView;