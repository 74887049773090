// components/Grid/Grid.js

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { dataFetch } from '../../actions/Data';

import Error404 from '../Error404';
import ListFilter from '../ListFilter';
import ListSwitchView from '../ListSwitchView';
import GridCard from './GridCard';
import GridCardPhantom from './GridCardPhantom';

class Grid extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filterBy: this.props.match.params.itemparam ? 'richiedente' : '',
      filterText: this.props.match.params.itemparam || '',
      list: [...props.list],
      title: null,
      total: props.list.length
    }

    this.setFilterText = this.setFilterText.bind(this);
  }

  componentDidMount = () => {
    this.props.dataFetch(this.props.match.params.itemtype);
    this.setPageTitle();
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.match.params.itemtype !== this.props.match.params.itemtype) {
      this.props.dataFetch(nextProps.match.params.itemtype);
      this.resetFilters();
    }
  }

  componentWillUpdate = (nextProps, nextState) => {
    if (
      nextState.filterBy !== this.state.filterBy
      || nextState.filterText !== this.state.filterText
      || nextProps.list !== this.props.list
    ) {
      let list = [...nextProps.list];

      if (nextState.filterText) {
        const filterBy = nextState.filterBy ? [ { field: nextState.filterBy } ] : this.props.cols;
        list = list.filter(item => filterBy.map(col => {
          return item[col.field];
        }).join(' ').toLowerCase().search(nextState.filterText.toLowerCase()) !== -1);
      }

      this.setState({ list });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.params.itemtype !== this.props.match.params.itemtype
      || prevState.list.length !== this.state.list.length
    ) {
      this.setPageTitle();
    }
  }

  resetFilters = () => {
    const filterBy = '';
    const filterText = '';
    this.setState({ filterBy, filterText });
  }

  setFilterText = e => {
    const filterText = e.target.value;
    this.setState({ filterText });
  }

  setFilterBy = e => {
    const filterBy = e.target.value;
    this.setState({ filterBy });
  }

  setPageTitle = () => {
    const title = this.props.match.params.itemtype.replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
    const total = this.state.list.length;
    this.setState({ title, total });
  }

  cards = (cols, list) => {
    if (list instanceof Array) {
      const type = this.props.match.params.itemtype;
      let cardsArray = [];
      list.map(function (card, i) {
        cardsArray.push(<GridCard type={ type } card={ card } cols={ cols } key={ i } />);
        if ((i + 1) % 4 === 0) cardsArray.push(<div className="w-100 my-3" key={ i + ".5" }></div>);
        return true;
      });

      [...Array(4 - (list.length % 4))].map((x, i) =>
        cardsArray.push(<GridCardPhantom key={ "p" + i } />)
      );

      return cardsArray;
    }
  }

  render = () => {
    const { changeViewType, cols, is404, view } = this.props;
    const { filterBy, filterText, list, title, total } = this.state;
    if (is404) return <Error404 />;
    return (
      <section>
        <div className="row justify-content-between my-4">
          <div className="col-4" id="title"><h2>{ title } <span className="badge badge-secondary align-middle">{ total }</span></h2></div>
          <ListFilter cols={ cols } filterBy={ filterBy } setFilterBy={ this.setFilterBy } filterText={ filterText } setFilterText={ this.setFilterText } resetFilters={ this.resetFilters } />
          <ListSwitchView view={ view } changeViewType={ changeViewType } />
        </div>
        <div className="card-deck">
          { this.cards(cols, list) }
        </div>
      </section>
    );
  }

}

const mapStateToProps = (state) => {
  const { data } = state;
  const { cols, is404, list } = data;
  return {
    cols,
    is404,
    list
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dataFetch: (type) => dispatch(dataFetch(type))
  };
};

Grid.propTypes = {
  changeViewType: PropTypes.func,
  cols: PropTypes.array,
  is404: PropTypes.bool,
  list: PropTypes.array,
  view: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(Grid);