// index.js

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';

import fontawesome from '@fortawesome/fontawesome';
import faAngleDoubleLeft from '@fortawesome/fontawesome-free-solid/faAngleDoubleLeft';
import faAngleDoubleRight from '@fortawesome/fontawesome-free-solid/faAngleDoubleRight';
import faBolt from '@fortawesome/fontawesome-free-solid/faBolt';
import faCalendarAlt from '@fortawesome/fontawesome-free-regular/faCalendarAlt';
import faCaretDown from '@fortawesome/fontawesome-free-solid/faCaretDown';
import faCaretUp from '@fortawesome/fontawesome-free-solid/faCaretUp';
import faCheck from '@fortawesome/fontawesome-free-solid/faCheck';
import faChild from '@fortawesome/fontawesome-free-solid/faChild';
import faCircle from '@fortawesome/fontawesome-free-solid/faCircle';
import faEdit from '@fortawesome/fontawesome-free-regular/faEdit';
import faFilePdf from '@fortawesome/fontawesome-free-regular/faFilePdf';
import faHeartbeat from '@fortawesome/fontawesome-free-solid/faHeartbeat';
import faHome from '@fortawesome/fontawesome-free-solid/faHome';
import faHotjar from '@fortawesome/fontawesome-free-brands/faHotjar';
import faPhone from '@fortawesome/fontawesome-free-solid/faPhone';
import faPlusSquare from '@fortawesome/fontawesome-free-regular/faPlusSquare';
import faSignOutAlt from '@fortawesome/fontawesome-free-solid/faSignOutAlt';
import faSortAmountDown from '@fortawesome/fontawesome-free-solid/faSortAmountDown';
import faSortAmountUp from '@fortawesome/fontawesome-free-solid/faSortAmountUp';
import faSpinner from '@fortawesome/fontawesome-free-solid/faSpinner';
import faTable from '@fortawesome/fontawesome-free-solid/faTable';
import faTh from '@fortawesome/fontawesome-free-solid/faTh';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';
import faTrashAlt from '@fortawesome/fontawesome-free-regular/faTrashAlt';

import configureStore from './store/configureStore';

import App from './components/App';

fontawesome.library.add(
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faBolt,
  faCalendarAlt,
  faCaretDown,
  faCaretUp,
  faCheck,
  faChild,
  faCircle,
  faEdit,
  faFilePdf,
  faHeartbeat,
  faHome,
  faHotjar,
  faPhone,
  faPlusSquare,
  faSignOutAlt,
  faSortAmountDown,
  faSortAmountUp,
  faSpinner,
  faTable,
  faTh,
  faTimes,
  faTrashAlt
);

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);